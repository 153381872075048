/**
 *
 * AlertError
 *
 */
import React, { useState } from 'react';

import { Box } from 'app/components/Box';
import { Dialog } from 'app/components/Dialog';
import { DialogActions } from 'app/components/Dialog/DialogActions';
import { DialogContent } from 'app/components/Dialog/DialogContent';
import { Typography } from 'app/components/Typography';
import { AlertActionButton } from 'app/containers/Alerts/AlertActionButton';
import { AlertTitle } from 'app/containers/Alerts/AlertTitle';
import { useAlerts } from 'app/containers/Alerts/Context';
import { IAlertDefault } from './types';
import { AlertCheckbox } from './AlertCheckbox';
import { Message } from 'app/components/Dialog/Message';
import { TextField } from 'app/components/Inputs/TextField';

interface IAlertDefaultProps extends IAlertDefault {}

export function AlertDefault({
  id,
  text,
  title,
  width,
  testId,
  actions,
  inputProps,
  checkBoxes,
  messages,
}: IAlertDefaultProps) {
  const { removeAlert } = useAlerts();

  const [isValuePresented, setIsValuePresented] = useState<boolean>();

  const handleClose = () => {
    removeAlert(id);
  };

  return (
    <Dialog
      id={id}
      data-test={testId}
      open
      onClose={handleClose}
      fullWidth
      maxWidth={width || 'sm'}
    >
      <AlertTitle onClose={handleClose}>{title}</AlertTitle>
      {!!text && (
        <DialogContent>
          <Box display="flex">
            <Typography style={{ whiteSpace: 'pre-line' }}>{text}</Typography>
          </Box>
          {!!checkBoxes &&
            checkBoxes.map(checkBox => <AlertCheckbox {...checkBox} />)}
          {!!messages && messages.map(message => <Message {...message} />)}
        </DialogContent>
      )}
      {!!inputProps && (
        <DialogContent>
          <TextField
            {...inputProps}
            onChange={e => {
              setIsValuePresented(!!e.target.value.trim());
              if (inputProps.onChange) {
                inputProps.onChange(e);
              }
            }}
            margin="none"
          />
        </DialogContent>
      )}
      <DialogActions>
        {actions?.map(action => (
          <AlertActionButton
            key={action.title}
            alertId={id}
            {...action}
            valueRequired={action.valueRequired && !isValuePresented}
          />
        ))}
      </DialogActions>
    </Dialog>
  );
}
